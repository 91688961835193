<template>
    <!--START WATERMARK -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div :class="{on:isSceneActive}"
             class="video_settings_button"
             @click="() => { $emit('setActiveChild',$options.name) ; toggleWatermark(true); }"
        >
            <strong>Watermark</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="price_type_block clr" style="padding-top: 6px;">
                <div class="price_type_left">
                    <div :class="{active:!data.watermark.active}"
                         class="open_popup_1"
                         @click="() => { $emit('setActiveChild','SpeedAndTiming') ; toggleWatermark(false); }"
                    >
                        <div>No Watermark <span class="material-icons" style="top: -3px;">not_interested</span></div>
                    </div>
                </div>
                <div class="price_type_right">
                    <div :class="{active:data.watermark.active}"
                         class="open_popup_1"
                    >
                        <div>With Watermark <span class="material-icons" style="top: -3px; margin-left: 2px;">fingerprint</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="data.watermark.active"
                 class="editor_block editor_block_logo"
            >
                <div class="editor_logo_container_left editor_watermark_display">
                    <div class="editor_logo_display">
                        <mini-video
                            :data="data"
                        />
                    </div>
                </div>
                <div class="editor_logo_container_right">
                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 0;">
                        <label class="mylabel_1" for="watermark_scale"><span>Watermark Scale</span></label>
                        <custom-input
                            :id="'Scale'"
                            :increment="5"
                            :max="300"
                            :min="0"
                            :unit="'%'"
                            :value="data.watermark.scale"
                            @updateInput="updateScale"
                        />
                    </div>

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="watermark_posX"><span>Horizontal Position</span></label>
                        <div class="input_numb_block">
                            <custom-input
                                :id="'X'"
                                :increment="5"
                                :max="120"
                                :min="-20"
                                :unit="'%'"
                                :value="data.watermark.x"
                                @updateInput="updateXPosition"
                            />
                        </div>
                    </div>

                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="watermark_posY"><span>Vertical Position</span></label>
                        <custom-input
                            :id="'Y'"
                            :increment="5"
                            :max="120"
                            :min="-20"
                            :unit="'%'"
                            :value="data.watermark.y"
                            @updateInput="updateYPosition"
                        />
                    </div>

                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END WATERMARK -> VIDEO SETTINGS-->
</template>

<script>
import CustomInput from "../../common/CustomInput.vue";
import MiniVideo from "./common/MiniVideo.vue";


export default {
    name: "Watermark",
    components: {
        CustomInput,
        MiniVideo
    },
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        selectedScenes: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        },
        isSceneActive() {
            const sceneIdName = this.$options.name.charAt(0).toLowerCase() + this.$options.name.slice(1) + 'IframeId';
            if (sceneIdName in this.selectedScenes) {
                return this.selectedScenes[sceneIdName] !== null;
            }
            return false;
        }
    },
    methods: {
        toggleWatermark(value) {
            this.$emit('toggleWatermark', value)
        },
        updateScale(value) {
            this.$emit('updateWatermarkScale', value)
        },
        updateXPosition(value) {
            this.$emit('updateWatermarkPositionX', value)
        },
        updateYPosition(value) {
            this.$emit('updateWatermarkPositionY', value)
        }
    }

}
</script>

<style scoped>

</style>
