<template>
    <div class="videos_spacer"></div>
    <div class="content_description" v-if="localAnimation">
        <div class="content_title_with_filters">
            <h1>Video #{{ localAnimation.name }}</h1>
            <filter-aspect-ratio :aspect-ratio="localAspectRatio" @change-aspect-ratio="changeAspectRatio"/>
        </div>
    </div>

    <div class="vid_preview_container clr">
        <div class="vid_preview_preloader"
             v-if="localAnimation === null">
            <!-- Preloader content adapted for Vue component, ensuring consistency with Blade template -->
            <div class="pframes_preloader">
                <div class="iframe_loader">
                    <div class="lds-ring lds-ring12">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <span class="lds-image lds-lion"></span><span class="lds-image lds-play"></span>
                    </div>
                </div>
                <div class="pframes_preloader_message_block">
                    <div class="pframes_preloader_message"></div>
                </div>
            </div>
        </div>

        <div class="vid_preview_block clr" v-if="localAnimation">
            <div class="vid_preview_left_block clr">
                <animation-iframe
                    v-if="['not-rendered', 'rendering', 'pending'].includes(animationStatus) && localAnimation"
                    :aspect-ratio="localAspectRatio"
                    :animation-name="localAnimation.name"
                    :window-width="windowWidth"
                />
                <transition name="fade" v-if="animationStatus === 'rendered'">
                    <div class="vid_main_container vid_movie_container">
                        <video class="vid_movie" autoplay muted loop controls
                               :poster="getAnimationScreenshots(4)" :key="animationVideoSrc">
                            <source :src="animationVideoSrc" type="video/mp4"/>
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                    </div>
                </transition>

                <div v-if="['not-rendered', 'rendering'].includes(animationStatus)" class="vid_message">
                    <span class="material-icons">notifications_active</span>
                    <strong>Rendering is necessary to unlock all the features</strong>, such as smooth motions, video sound, thumbnails, high quality, and download.
                </div>

                <!--CREATED FROM URL / PROMPT + HAS DESCRIPTION OR HAS TAGS-->
                <div class="vid_hunt" v-if="localAnimation.resume && localAnimation.resume.length">
                    <h3>Social Media Post</h3>
                    <!--IF HAS DESCRIPTION-->
                        <p v-html="localAnimation.resume"></p>
                    <!--ENDIF HAS DESCRIPTION-->

                    <!--IF HAS URL-->
                        <p class="vid_hunt_tags" style="line-height: 18px;" v-if="localAnimation.generated_website_url && localAnimation.generated_website_url.length">
                            <span>Link:&nbsp;</span>
                            <a :href="localAnimation.generated_website_url" target="_blank" rel="noopener noreferrer">{{ localAnimation.generated_website_url }}</a>
                        </p>
                    <!--ENDIF HAS URL-->

                    <!--IF HAS TAGS-->
                        <p class="vid_hunt_tags" v-html="animationTags"></p>
                    <!--ENDIF HAS TAGS-->
                </div>
                <!--CREATED FROM URL / PROMPT + HAS DESCRIPTION OR HAS TAGS-->

                <div v-if="animationStatus === 'rendered'" class="thumb_block">
                    <!--<h3>{{ uppercaseAspectRatio }} Video #{{ localAnimation.name }} Thumbnails</h3>-->
                    <h3>Video Thumbnails</h3>
                    <div class="thumb_content clr">
                        <ul class="clr">
                            <li v-for="(screenshot, index) in getAnimationScreenshots(2, 7)" :key="index">
                                <a :href="screenshot" download>
                                    <img
                                        :alt="`${uppercaseAspectRatio} Video #${localAnimation.name} Thumbnail ${index + 1}`"
                                        :src="screenshot">
                                    <span class="material-icons">file_download</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="vid_preview_right_block">
                <div class="vid_data_block">
                    <preview-actions
                        v-if="localAnimation"
                        :owner="localOwner"
                        :animation="localAnimation"
                        :animation-status="animationStatus"
                        :aspect-ratio="localAspectRatio"
                        :category="localCategory"
                        :isOwner="localIsOwner"
                        :is-admin="localIsAdmin"
                        :is-guest="localIsGuest"
                        :pending-spot="localPendingSpot"
                        :rendering-time="renderingTime[localAspectRatio]"
                        :initial-rendering-time-stored="initialRenderingTimeStored[localAspectRatio]"
                        :areRenderingSpotsAvailable="localRenderingSpotsAvailable"
                        :reactivity-trigger="reactivityTrigger"
                        @trigger-render="triggerRender"
                        @toggle-template="toggleTemplate"
                    />
                </div>
            </div>
        </div>


    </div>
</template>


<script>
import PreviewActions from "../components/AnimationView/PreviewActions.vue";
import AnimationIframe from "../components/AnimationView/AnimationIframe.vue";
import FilterAspectRatio from "../components/AnimationView/FilterAspectRatio.vue";

export default {
    name: "AnimationView",
    components: {PreviewActions, AnimationIframe, FilterAspectRatio},
    props: {
        animation: {
            type: Object,
            default: () => ({}),
        },
        category: {
            type: Object,
            default: () => ({}),
        },
        owner: {
            type: Object,
            default: () => ({}),
        },
        isOwner: {
            type: Boolean,
            default: false
        },
        aspectRatio: {
            type: String,
            default: 'square',
        },
        animationIsRendering: {
            type: Boolean,
            default: false,
        },
        isGuest: {
            type: Boolean,
            default: false,
        },
        isAdmin: {
            type: [Number, Boolean],
            default: false,
        },
        renderingSpotsAvailable: {
            type: Boolean,
            default: false,
        },
        initialRenderingTime: {
            type: Number,
            default: 0,
        },
    },
    data: () => {
        return {
            windowWidth: 1024,
            localOwner: {},
            localIsOwner: false,
            localIsAdmin: false,
            localIsGuest: true,
            localAnimation: null,
            currentPendingSpot: {
                landscape: 0,
                portrait: 0,
                square: 0
            },
            localCategory: null,
            localAspectRatio: 'square',
            localRenderingSpotsAvailable: false,
            renderingTime: {
                landscape: 160,
                portrait: 160,
                square: 160
            },
            initialRenderingTimeStored: {
                landscape: 160,
                portrait: 160,
                square: 160
            },
            pendingSpot: {
                landscape: 10,
                portrait: 10,
                square: 10
            },
            renderingTimeIntervalId: {
                landscape: null,
                portrait: null,
                square: null
            },
            initialPendingSpots: {
                landscape: 10,
                portrait: 10,
                square: 10
            },
            pendingSpotIntervalId: {},
            updateRenderingStatusIntervalId: null,
            renderingStatusCheckIntervalId: {},
            savedTimeKey: null,
            savedSpotKey: null,
            reactivityTrigger: 0
        }
    },
    mounted() {
        localStorage.clear();
        this.localOwner = this.owner;
        this.localIsOwner = this.isOwner;
        this.localIsAdmin = Boolean(this.isAdmin);
        this.localIsGuest = this.isGuest;
        this.localAnimation = this.animation;
        this.localCategory = this.category;
        this.localAspectRatio = this.aspectRatio;
        this.localRenderingSpotsAvailable = this.renderingSpotsAvailable;
        this.renderingTime[this.localAspectRatio] = this.initialRenderingTime;
        this.initialRenderingTimeStored[this.localAspectRatio] = this.initialRenderingTime;
        this.savedTimeKey = `renderingTime-${this.localAnimation.name}-aspect-${this.localAspectRatio}`;
        this.savedSpotKey = `pendingSpot-${this.localAnimation.name}-aspect-${this.localAspectRatio}`;

        this.updateAspectRatioUrl();
        this.updateAspectRatioContainer();
        if (['not-rendered', 'rendering', 'pending'].includes(this.animationStatus)) {
            this.handleResize();
        }
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleResize);
            this.handleResize(); // Initial set
        });

        if (this.animationStatus !== 'rendered') {
            this.initializeCheckRender();
        } else {
            clearInterval(this.renderingTimeIntervalId[this.localAspectRatio]);
            clearInterval(this.pendingSpotIntervalId[this.localAspectRatio]);

            clearInterval(this.renderingStatusCheckIntervalId[this.localAspectRatio]);
            clearInterval(this.updateRenderingStatusIntervalId);
        }

        window.addEventListener('beforeunload', this.clearAllIntervals);
    },
    watch: {
        animationStatus(newStatus, oldStatus) {
            // console.log('animationStatus changed::', newStatus);
            this.reactivityTrigger++;
            if (newStatus === 'rendered') {
                clearInterval(this.renderingTimeIntervalId[this.localAspectRatio]);
                clearInterval(this.pendingSpotIntervalId[this.localAspectRatio]);

                clearInterval(this.renderingStatusCheckIntervalId[this.localAspectRatio]);
                clearInterval(this.updateRenderingStatusIntervalId);
                localStorage.removeItem(this.savedTimeKey);
                localStorage.removeItem(this.savedSpotKey);
            }

            if ('rendering' === newStatus) {
                this.initializeCheckRender();
            }

            if ('rendered' === newStatus) {
                this.clearAllIntervals(this.localAspectRatio);
            }

            if (newStatus !== 'pending') {
                clearInterval(this.pendingSpotIntervalId[this.localAspectRatio]);

                localStorage.removeItem(`pendingSpot-${this.localAnimation.name}-aspect-${this.localAspectRatio}`);
            }
        },
        watch: {
            pendingSpot: {
                handler(newValue, oldValue) {
                    this.reactivityTrigger++;
                    // console.log('pendingSpot changed::', newValue);
                },
                deep: true
            },
            localAspectRatio(newAspectRatio, oldAspectRatio) {
                if (this.animationStatus === 'rendered') {
                    clearInterval(this.renderingTimeIntervalId[oldAspectRatio]);
                    clearInterval(this.pendingSpotIntervalId[oldAspectRatio]);
                    clearInterval(this.renderingStatusCheckIntervalId[oldAspectRatio]);
                } else if (this.animationStatus === 'rendering') {
                    clearInterval(this.renderingTimeIntervalId[oldAspectRatio]);
                    this.initializeCheckRender();
                } else if (this.animationStatus === 'pending') {
                    clearInterval(this.pendingSpotIntervalId[oldAspectRatio]);
                    this.initializeCheckRender();
                } else {
                    this.initializeCheckRender();
                }
            },
            animationStatus: {
                handler(newValue, oldValue) {
                    this.reactivityTrigger++;
                    // console.log('animation status changed::', newValue);
                },
                deep: true
            }
        }
    },
    computed: {
        uppercaseAspectRatio() {
            return this.localAspectRatio.charAt(0).toUpperCase() + this.localAspectRatio.slice(1).toLowerCase();
        },
        animationStatus() {
            if (this.localAnimation) {
                return this.localAnimation[this.localAspectRatio + '_status'];
            }
            return 'not-rendered';
        },
        animationVideoSrc() {
            return this.localAnimation[this.localAspectRatio + '_path'];
        },
        localPendingSpot() {
            // console.log('localPendingSpot::', this.pendingSpot[this.localAspectRatio]);
            return this.pendingSpot[this.localAspectRatio];
        },
        currentUrl() {
            return this.localAnimation.generated_website_url;
        },
        animationTags(){
            return this.localAnimation.tags
                .split(',')
                .map(tag => `#${tag.trim()}`)
                .join(' ');
        }
    },
    methods: {
        getAnimationScreenshots(start = 1, end = null) {
            // Define the base path for the screenshots based on the aspect ratio
            const basePath = this.localAnimation[this.localAspectRatio + '_thumbs_path'];

            // If an end value is provided, generate an array of image paths
            if (end) {
                // Generate a range of numbers from start to end
                const numbers = Array.from({length: end - start + 1}, (_, i) => start + i);

                // Map each number to its corresponding screenshot path
                return numbers.map(number => {
                    // Concatenate the base path with the number and file extension
                    return `${basePath}${number}.png`;
                });

            }

            // If no end value is provided, return the path for a single image
            // This constructs the path by appending the start number and file extension to the base path
            return `${basePath}${start}.png`;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.updateAspectRatioContainer();
        },
        updateAspectRatioContainer() {
            const platform_content = document.querySelector('.platform_content');
            if (!platform_content) return;

            platform_content.className = `platform_content dsg_is_${this.localAspectRatio}`;
        },
        changeAspectRatio(newAspectRatio) {
            this.localAspectRatio = newAspectRatio;

            this.setAspectRatioCookie();
            this.updateAspectRatioUrl();
            this.updateAspectRatioContainer();
        },

        setAspectRatioCookie() {
            const days = 365;
            let expires = "";
            if (days) {
                const date = new Date;
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = "aspectRenderLion" + "=" + (this.localAspectRatio || "") + expires + "; path=/";
        },

        updateAspectRatioUrl() {
            const knownAspectRatios = ['square', 'landscape', 'portrait'];
            const baseUrl = window.location.protocol + "//" + window.location.host;
            let pathSegments = window.location.pathname.split('/').filter(Boolean); // Split and remove any empty segments

            // Try to find and replace an existing aspect ratio in the URL
            const existingAspectRatioIndex = pathSegments.findIndex(segment => knownAspectRatios.includes(segment));
            if (existingAspectRatioIndex !== -1) {
                // Replace existing aspect ratio
                pathSegments[existingAspectRatioIndex] = this.localAspectRatio;
            } else {
                // Assume the aspect ratio should be appended at the end if not found
                pathSegments.push(this.localAspectRatio);
            }

            // Construct the new URL and update it
            //const newPath = '/' + pathSegments.join('/');
            //const newUrl = baseUrl + newPath + window.location.search; // Preserve any query parameters
            //window.history.pushState({path: newUrl}, '', newUrl);
            const newPath = '/' + pathSegments.join('/');
            const newUrl = baseUrl + newPath + window.location.search; // Preserve any query parameters

            // Only push state if the URL is different from the current URL
            if (window.location.pathname + window.location.search !== newPath + window.location.search) {
                window.history.pushState({path: newUrl}, '', newUrl);
            }
        },
        async triggerRender(renderType) {
            let response = await axios.post(`/api/animation/render/${this.localAnimation.name}/${this.localAspectRatio}/${renderType}`);
            if (response.data) {
                this.clearAllIntervals(this.localAspectRatio);
                if(response.data.redirect){
                        window.location.href = response.data.route;
                        return;
                }
                this.localAnimation = {...this.localAnimation, ...response.data.animation};

                this.localRenderingSpotsAvailable = response.data.render_spots_available;

                this.pendingSpot[this.localAspectRatio] = response.data.pending_spot;
                this.initialPendingSpots[this.localAspectRatio] = response.data.pending_spot;
                this.currentPendingSpot[this.localAspectRatio] = response.data.pending_spot;

                this.renderingTime[this.localAspectRatio] = response.data.rendering_time;
                this.initialRenderingTimeStored[this.localAspectRatio] = response.data.rendering_time;

                this.updateUserHeader(response.data);

                this.initializeCheckRender(true);
                // this.triggerNotification('Video Rendering Underway...');
                if (this.pendingSpot[this.localAspectRatio] <= 10) {
                    await this.checkRenderingStatus(this.localAspectRatio);
                }
            } else {
                console.error(response.data);
            }
        },

        updateUserHeader(data) {
            if (data.logged_in_user_dropdown) {
                const dropdown = document.getElementById('user-header-dropdown');
                if (dropdown) {
                    dropdown.innerHTML = data.logged_in_user_dropdown;
                }
            }
        },
        async toggleTemplate() {
            let response = await axios.post(`/api/animation/toggle-template/${this.localAnimation.name}`);
            if (response.data) {
                this.localAnimation = {...this.localAnimation, ...response.data.animation};

            } else {
                console.error(response.data);
            }
        },

        initializeCheckRender(skip_check=false) {
            if (!skip_check && this.renderingStatusCheckIntervalId[this.localAspectRatio]) {
                return;
            }
            this.renderingStatusCheckIntervalId[this.localAspectRatio] = setInterval(() => {
                // console.log('Checking render status every 10 seconds');
                this.checkRenderingStatus(this.localAspectRatio);
            }, 10000); // 10 seconds
        },
        async checkRenderingStatus(aspectRatio) {
            let response = await axios.get(`/api/renders/pending-status/${this.localCategory.slug}/${this.localAnimation.name}/${aspectRatio}`);
            if (response.data) {
                let data = response.data;
                this.localAnimation = {...this.localAnimation, ...data.animation};

                // Clear interval for current aspect ratio
                this.localRenderingSpotsAvailable = data.render_spots_available;
                this.currentPendingSpot[this.localAspectRatio] = data.pending_spot;

                // Initialize or re-initialize pending spot interval
                if (this.animationStatus === 'rendering') {
                    this.initializeRenderingTime(data);
                } else if (this.animationStatus === 'pending') {
                    this.initializePendingSpot(data);
                } else {
                    this.clearAllIntervals(aspectRatio);
                }
            } else {
                console.error(response.data);
            }
        },
        initializeRenderingTime(data) {
            const aspect = this.localAspectRatio;

            // Check if an interval already exists for this aspect ratio. If it does, exit the function.
            if (this.renderingTimeIntervalId[aspect] || this.animationStatus === 'rendered') {
                // console.log('cannot initialize rendering time because is already initialized');
                return;
            }
            this.renderingTime[aspect] = data.rendering_time;
            this.initialRenderingTimeStored[aspect] = data.rendering_time;
            // Ensure that savedTime is initialized correctly.
            let savedTime = this.renderingTime[aspect];
            if (!savedTime || savedTime <= 0) {
                savedTime = 120; // Default value or initialize from a variable/source as needed.
                this.renderingTime[aspect] = savedTime; // Initialize renderingTime for the current aspect ratio.
            }

            // Clear any previously set interval for this aspect ratio as a precaution.
            clearInterval(this.renderingTimeIntervalId[aspect]);

            // Set a new interval for the current aspect ratio.
            this.renderingTimeIntervalId[aspect] = setInterval(() => {
                // Decrement the renderingTime for the current aspect.
                if (this.renderingTime[aspect] > 0) {
                    this.renderingTime[aspect] -= 1;
                }

                // When the time reaches 0, reset it to 30 seconds.
                if (this.renderingTime[aspect] === 0 && this.animationStatus === 'rendering') {
                    this.renderingTime[aspect] = 30;
                    // Also, update the initialRenderingTimeStored to 30, assuming such a variable exists for tracking initial times.
                    this.initialRenderingTimeStored = 30; // Ensure this aligns with how you're using initialRenderingTimeStored in your component.
                } else if (this.animationStatus === 'rendered') {
                    clearInterval(this.renderingTimeIntervalId[aspect]);
                    clearInterval(this.renderingStatusCheckIntervalId[aspect]);
                }
                // console.log('renderingTime changed::' + this.renderingTime[aspect] + ' animation status is::' + this.animationStatus);

                // Here, include any logic needed to react to the change in rendering time, such as emitting events or updating other parts of your component.

            }, 1000); // The interval runs every 1000 milliseconds (1 second).
        },

        initializePendingSpot(data) {
            // Clear any existing interval
            if (this.pendingSpotIntervalId[this.localAspectRatio]) {
                return;
            }
            // console.log('Setting up pendingSpot interval for aspect ratio:', this.localAspectRatio);
            // Ensure only one interval per aspect ratio runs at a time
            clearInterval(this.pendingSpotIntervalId[this.localAspectRatio]);

            this.pendingSpotIntervalId[this.localAspectRatio] = setInterval(() => {
                if (this.currentPendingSpot[this.localAspectRatio] > this.initialPendingSpots[this.localAspectRatio]) {
                    this.pendingSpot[this.localAspectRatio] = this.currentPendingSpot[this.localAspectRatio] - (this.initialPendingSpots[this.localAspectRatio] - this.pendingSpot[this.localAspectRatio]);
                    this.initialPendingSpots[this.localAspectRatio] = this.currentPendingSpot[this.localAspectRatio];
                }

                if (this.animationStatus === 'pending' && this.pendingSpot[this.localAspectRatio] > 0) {
                    if (this.currentPendingSpot[this.localAspectRatio] < this.pendingSpot[this.localAspectRatio]) {
                        this.pendingSpot[this.localAspectRatio] = this.currentPendingSpot[this.localAspectRatio];
                    } else {
                        this.pendingSpot[this.localAspectRatio] -= 1;
                    }
                } else if (this.pendingSpot[this.localAspectRatio] <= 0) {
                    this.pendingSpot[this.localAspectRatio] = 10;
                }
                // console.log('Updated pendingSpot for', this.localAspectRatio, ':', this.pendingSpot[this.localAspectRatio]);
            }, 12000); // Update every 12 seconds
        },
        triggerNotification(message, level = 'success') {
            const notification = document.getElementById('dynamic-notification');
            notification.classList.add(level);
            notification.classList.remove('none');
            notification.textContent = message;
            setTimeout(() => {
                notification.classList.add('none');

            }, 4500)
        },
        clearAllIntervals(aspectRatio = null) {
            // console.log('clear all intervals called::', aspectRatio)
            if (aspectRatio) {
                clearInterval(this.renderingTimeIntervalId[aspectRatio]);
                clearInterval(this.pendingSpotIntervalId[aspectRatio]);
                clearInterval(this.renderingStatusCheckIntervalId[aspectRatio]);
            } else {
                clearInterval(this.renderingTimeIntervalId['square']);
                clearInterval(this.renderingTimeIntervalId['portrait']);
                clearInterval(this.renderingTimeIntervalId['landscape']);

                clearInterval(this.pendingSpotIntervalId['landscape']);
                clearInterval(this.pendingSpotIntervalId['portrait']);
                clearInterval(this.pendingSpotIntervalId['square']);

                clearInterval(this.renderingStatusCheckIntervalId['landscape']);
                clearInterval(this.renderingStatusCheckIntervalId['portrait']);
                clearInterval(this.renderingStatusCheckIntervalId['square']);
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.clearAllIntervals);
        this.clearAllIntervals();
    },
}
</script>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 2s linear infinite;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
    opacity: 0;
}
</style>
