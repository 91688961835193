<template>
    <div class="vid_data_details" v-if="owner">
        <div class="vid_data_details_avatar">
            <div>
                <strong>{{ ownerFirstLetter }}</strong>
            </div>
        </div>
        <div class="vid_data_details_title">
            <span>by</span>
            {{ owner.username }}
        </div>
        <div class="vid_data_details_type">
            {{ category.name }}
        </div>
        <div :class="['vid_data_details_status', animationStatus]">
            {{ formattedAnimationStatus }}
        </div>

        <!--START TEXT TO SPEECH if USER OWNER-->
        <div class="hunt_voice_block" v-if="owner">
            <div class="hunt_voice_content" v-if="2===3">
                <!--START SPEACH ON/OFF BUTTON
                    - IF USER HAS CREDITS/RENDERS
                        - ADD .active if Text to Speech is ON
                        - onClick set Text to Speech ON/OFF
                    - IF USER HAS NOT CREDITS/RENDERS
                        - ADD .inactive as DEFAULT
                        - onClick go to Pricing Page
                -->
                    <div class="hunt_voice_onoff_button" title="Text to Speech">
                        <div class="hunt_voice_onoff_inner">
                            <span class="material-icons material-icons-mic_on">mic</span>
                            <span class="material-icons material-icons-mic_off">mic_off</span>
                            <!--UPDATE VOICE NAME-->
                            <strong>Scarlett <em>(W)</em></strong>
                        </div>
                    </div>
                <!--END SPEACH ON/OFF BUTTON-->

                <!--START SPEACH SETTINGS BUTTONS - IF USER HAS CREDITS/RENDERS-->
                    <div class="hunt_voice_settings_button" title="Text to Speech Settings">
                        <span class="material-icons">settings</span> Settings
                    </div>
                <!--END SPEACH SETTINGS BUTTONS-->

                <!--START SPEACH SETTINGS BLOCK - IF USER HAS CREDITS/RENDERS
                    - ADD .active to open Popup
                -->
                    <div class="hunt_voice_popup popup_block">
                        <div class="popup_container">
                            <!--UPDATE THE CLOSE POPUP BUTTON ACTION-->
                            <div class="popup_close_button" title="Close">
                                <div><span class="material-icons">close</span></div>
                            </div>
                            <div class="popup_content">
                                <h2>Text to Speech Settings</h2>

                                <div class="speech_block">

                                    <!--START to CHOOSE VOICE-->
                                    <div class="speech_voice">
                                    	<!--ADD .active TO OPEN DROPDOWN MENU-->
                                    	<div class="hunt_options_block hunt_sound_block">
                                    		<!--CHOOSE VOICE BUTTON-->
                                    		<div class="hunt_options_button hunt_sound_button clr">
                                    			<dfn>Select Voice:</dfn>
                                    			<strong>Scarlett <em>(W)</em></strong>
                                    			<span class="material-icons">arrow_drop_down</span>
                                    		</div>

                                    		<!--VOICE LIST-->
                                    		<div class="hunt_options_list">
                                    			<div class="hunt_options_scroll">
                                    				<ul class="header_menu_expand_list">
                                    					<li class="hme_item">
                                    						<div class="hme_link"><strong>Scarlett <em>(W)</em></strong></div>
                                    						<!--PLAY THE VOICE -> ADD .active WHEN PLAYING-->
                                    						<div class="hme_play">
                                    						    <span class="material-icons material-icons_play">play_arrow</span>
                                    						    <span class="material-icons material-icons_pause">pause</span>
                                    						</div>
                                    					</li>
                                    					<li class="hme_item">
                                    						<div class="hme_link"><strong>Tom <em>(M)</em></strong></div>
                                    					    <!--PLAY THE VOICE -> ADD .active WHEN PLAYING-->
                                    						<div class="hme_play">
                                    						    <span class="material-icons material-icons_play">play_arrow</span>
                                    						    <span class="material-icons material-icons_pause">pause</span>
                                    						</div>
                                    					</li>
                                    					<li class="hme_item">
                                    						<div class="hme_link"><strong>Alex <em>(M)</em></strong></div>
                                    					    <!--PLAY THE VOICE -> ADD .active WHEN PLAYING-->
                                    						<div class="hme_play">
                                    						    <span class="material-icons material-icons_play">play_arrow</span>
                                    						    <span class="material-icons material-icons_pause">pause</span>
                                    						</div>
                                    					</li>
                                    					<li class="hme_item">
                                    						<div class="hme_link"><strong>Denisa <em>(W)</em></strong></div>
                                    					    <!--PLAY THE VOICE -> ADD .active WHEN PLAYING-->
                                    						<div class="hme_play">
                                    						    <span class="material-icons material-icons_play">play_arrow</span>
                                    						    <span class="material-icons material-icons_pause">pause</span>
                                    						</div>
                                    					</li>
                                    				</ul>
                                    			</div>
                                    		</div>
                                    	</div>
                                    </div>
                                    <!--END to CHOOSE VOICE-->

                                	<!--START to ADD/EDIT/UPDATE EACH SPEACH SLIDE CONTENT-->
                                	<div class="speech_content">
                                	    <!--
                                	        UPDATE SPEACH SLIDE ITEMS BY THE FOLLOWING RULES:
                                	            1. Shop Offer to Video
                                                    - SlideNumber: Number of Products [Min 1x]
                                                    - SlideLenght: VideoLenghtSeconds * 15 / SlideNumber
                                                2. Images to Video
                                                    - SlideNumber: 1x
                                                    - SlideLenght: VideoLenghtSeconds * 15
                                                3. Text to Video
                                                    - SlideNumber: Number of TextBlocks [Min 1x]
                                                    - SlideLenght: VideoLenghtSeconds * 15 / SlideNumber
                                                4. Animated Logo
                                                    - SlideNumber: 1x
                                                    - SlideLenght: VideoLenghtSeconds * 15
                                                6. Animated Wallpaper
                                                    - SlideNumber: 1x
                                                    - SlideLenght: VideoLenghtSeconds * 15
                                                7. Video Intro
                                                    - SlideNumber: 1x
                                                    - SlideLenght: VideoLenghtSeconds * 15
                                                8. Video Outro
                                                    - SlideNumber: 1x
                                                    - SlideLenght: VideoLenghtSeconds * 15
                                            Apllied Rule:
                                                - 750-1000 characters per 60 second video
                                	            - 900 characters per 60 seconds video [Choosed]
                                	            - 15 characters (inluding spaces) per 1 second video [Choosed]
                                	    -->
                                	    <ul>
                                	        <li>
                                	            <!--Update - SlideLenght -> maxlength-->
                                	            <label>Text to Speech</label>
                                	            <textarea minlength="0" maxlength="25000" rows="1" class="speech_content_input">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </textarea>
                                                <div class="speech_content_slidelenght">
                                                    <strong>466</strong> / <span>4188</span>
                                                </div>
                                	        </li>
                                	        <li>
                                	            <!--Update - SlideLenght -> maxlength-->
                                	            <label>Text to Speech</label>
                                	            <textarea minlength="0" maxlength="25000" rows="1" class="speech_content_input">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </textarea>
                                                <div class="speech_content_slidelenght">
                                                    <strong>466</strong> / <span>4188</span>
                                                </div>
                                	        </li>
                                	        <li>
                                	            <!--Update - SlideLenght -> maxlength-->
                                	            <label>Text to Speech</label>
                                	            <textarea minlength="0" maxlength="25000" rows="1" class="speech_content_input">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </textarea>
                                                <div class="speech_content_slidelenght">
                                                    <strong>466</strong> / <span>4188</span>
                                                </div>
                                	        </li>
                                	        <li>
                                	            <!--Update - SlideLenght -> maxlength-->
                                	            <label>Text to Speech</label>
                                	            <textarea minlength="0" maxlength="25000" rows="1" class="speech_content_input">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </textarea>
                                                <div class="speech_content_slidelenght">
                                                    <strong>466</strong> / <span>4188</span>
                                                </div>
                                	        </li>
                                	    </ul>
                                	</div>
                                	<!--END to ADD/EDIT/UPDATE EACH SPEACH SLIDE CONTENT-->

                                </div>
                            </div>
                            <div class="popup_footer">
                                <div class="popup_footer_block">
                                    <!--UPDATE TEXT TO SPEACH POPUP BUTTON-->
                                    <div class="popup_button_update">
                                        <div style="padding-right: 12px;"><strong>Update</strong><span class="material-icons" style="top: 14px; right: -10px;">chevron_right</span></div>
                                    </div>

                                    <!--CANCEL TEXT TO SPEACH POPUP BUTTON-->
                                    <div class="popup_button_cancel">
                                        <div style="padding-left: 16px;"> Cancel <span class="material-icons" style="top: 14px; left: -10px;">close</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!--END SPEACH SETTINGS BLOCK - IF USER HAS CREDITS/RENDERS-->
            </div>
        </div>
        <!--END TEXT TO SPEECH if USER OWNER-->

    </div>



    <rendered
        v-if="!limitReachedShown"
        :isGuest="isGuest"
        :isAdmin="isAdmin"
        :owner="owner"
        :isOwner="isOwner"
        :animation="animation"
        :aspect-ratio="aspectRatio"
        :reactivity-trigger="reactivityTrigger"
        :animationStatus="animationStatus"
        :areRenderingSpotsAvailable="areRenderingSpotsAvailable"
        :showLimitReachedPopUp="showLimitReachedPopUp"
        @trigger-render="triggerRender"
    />
    <pending
        v-if="!isGuest && animationStatus === 'pending' && isOwner"
        :animation="animation"
        :aspectRatio="aspectRatio"
        :reactivity-trigger="reactivityTrigger"
        :pendingSpot="pendingSpot"
    />
    <rendering
        v-if="!isGuest && animationStatus === 'rendering'"
        :animationStatus="animationStatus"
        :animationIsRendering="animationIsRendering"
        :aspectRatio="aspectRatio"
        :animation="animation"
        :renderingTime="renderingTime"
        :reactivity-trigger="reactivityTrigger"
        :initial-rendering-time-stored="initialRenderingTimeStored"
    />
    <limitReachedPopUp
        :areRenderingSpotsAvailable="areRenderingSpotsAvailable"
        :limitReachedShown="limitReachedShown"
        :owner="owner"
        :isAdmin="isAdmin"
        :showLimitReachedPopUp="showLimitReachedPopUp"
        @closeLimitReachedPopUp="closeLimitReachedPopUp"
    />
    <!--    <rendered-->
    <!--        :isGuest="isGuest"-->
    <!--        :isAdmin="isAdmin"-->
    <!--        :animation="animation"-->
    <!--    />-->
    <action-buttons
        v-if="!isGuest && isOwner"
        :isOwner="isOwner"
        :animation="animation"
        :category="category"
    />
    <use-as-template
        v-if="!isOwner && !isGuest"
        :animation="animation"
        :category="category"
    />
    <set-as-template
        v-if="isAdmin && canBeSeTaSetAsTemplate"
        :animation="animation"
        @toggleTemplate="toggleTemplate"
    />
</template>
<script>
import Rendered from "./buttons/Rendered.vue";
import Pending from "./buttons/Pending.vue";
import Rendering from "./buttons/Rendering.vue";
import ActionButtons from "./buttons/ActionButtons.vue";
import SetAsTemplate from "./buttons/SetAsTemplate.vue";
import UseAsTemplate from "./buttons/UseAsTemplate.vue";
import LimitReachedPopUp from "./LimitReachedPopUp.vue";

export default {
    name: "AnimationView",
    emits: ['triggerRender', 'toggleTemplate'],
    components: {UseAsTemplate, SetAsTemplate, ActionButtons, Rendered, Pending, Rendering, LimitReachedPopUp},
    props: {
        owner: {
            type: Object,
            default: () => ({})
        },
        pendingSpot: {
            type: Number,
            default: 0
        },
        animation: {
            type: Object,
            default: () => ({})
        },
        areRenderingSpotsAvailable: {
            type: Boolean,
            default: false
        },
        category: {
            type: Object,
            default: () => ({})
        },
        isOwner: {
            type: Boolean,
            default: false
        },
        aspectRatio: {
            type: String,
            default: 'square'
        },
        animationStatus: {
            type: String,
            default: 'not-rendered'
        },
        animationIsRendering: {
            type: Boolean,
            default: false
        },
        isGuest: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: [Number, Boolean],
        },
        renderingTime: {
            type: Number,
            default: 0
        },
        initialRenderingTimeStored: {
            type: Number,
            default: 0
        },
        reactivityTrigger: {
            type: Number,
            default: 0
        }
    },
    data: () => {
        return {
            showLimitReachedPopUp: false,
            limitReachedShown: false,
        }
    },
    computed: {
        ownerFirstLetter() {
            return this.owner.username.charAt(0).toUpperCase();
        },
        formattedAnimationStatus() {
            return this.animationStatus.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('-');
        },
        canBeSeTaSetAsTemplate() {
            return this.animation.can_be_set_as_template;
        }
    },
    mounted() {
        // console.log(this.animation);
        // console.log(this.owner);
        // console.log(this.isAdmin);
        // console.log('isGuest Preview Actions', this.isGuest);
        // console.log('CANSETASTEMPLATE:::'+this.animation.can_be_set_as_template);
    },
    watch: {
        pendingSpot(newVal) {
            // console.log('Pending Spot in PreviewActions:', newVal);
        }
    },
    methods: {
        toggleTemplate() {
            this.$emit('toggleTemplate');
        },
        triggerRender(renderType) {
            if (!this.areRenderingSpotsAvailable && !this.owner.has_credits && !this.isAdmin) {
                // If the condition is met (no spots, no credits, not admin), show the popup
                this.showLimitReachedPopUp = true;
            } else {
                // Otherwise (there are spots or the owner has credits or the user is an admin), trigger the render
                this.$emit('triggerRender', renderType);
            }

            // Log statements for debugging purposes
            // console.log('RENDERING SPOTS AVAILABLE:', this.areRenderingSpotsAvailable);
            // console.log('CONDITION CHECK:', ((!this.areRenderingSpotsAvailable && !this.owner.has_credits) || !this.isAdmin));
            // console.log('Owner has credits:', this.owner.has_credits);
        },
        closeLimitReachedPopUp() {
            this.showLimitReachedPopUp = false;
            this.limitReachedShown = true;
        }
    }
}
</script>
<style scoped>
</style>
