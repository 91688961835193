import {isNull} from "lodash";

export const requestDeleteFavorite = async (index, favoredType) => {
    const response = await axios.post(`/api/scene/toggle-favored`, {index, type: favoredType});
    if (response.data !== 'Deleted') {
        console.log('error request scene/toggle-favored', response)
    }
}

export const compressImage = (file, maxWidth, maxHeight, quality, callback, maxFileSize = 3) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate the new dimensions maintaining the aspect ratio
        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
            if (blob.size / 1024 / 1024 < maxFileSize) { // Check if image is less than 3 MB
                callback(blob);
            } else {
                compressImage(file, maxWidth, maxHeight, quality - 0.1, callback); // Reduce quality
            }
        }, 'image/jpeg', quality);
    };

    img.onerror = (error) => {
        console.error("There was an error processing the image", error);
    };
}

export const getFileSizeInMb = file => {
    if (!file.size) {
        return null;
    }
    return file.size / 1024 / 1024
}

export const getInvalidFile = files => Array.from(files).find(file => !validImageType(file.type))
export const isSvg = file_type => {
    file_type = file_type.split('/')
    if (!file_type[1]) {
        return false;
    }
    return file_type[1].includes('svg')
}
export const validImageType = file_type => {
    try {
        file_type = file_type.split('/')
        if (!file_type[1]) {
            return false;
        }
        file_type = file_type[1];
        return ['jpg', 'jpeg', 'png', 'svg', 'webp', 'svg+xml'].includes(file_type)
    } catch (e) {
        console.log("validImageType error", e);

        return false;

    }
}

export const getDefaultLogoPath = logoSrc => {
    if (logoSrc && logoSrc.includes('..')) {
        return 'https://dev2.renderlion.com/' + logoSrc.replace('../', '');
    } else if (logoSrc && logoSrc.includes('user')) {
        return 'https://storage.renderlion.com/' + logoSrc;
    }
    return logoSrc;
}

export const imageUrlToFile = async (url, fileName, process_url = true) => {
    try {
        if (process_url) {
            url = getDefaultLogoPath(url)
        }
        const response = await fetch(url);
        if (!response.ok) console.log('Network response was not ok.');
        const blob = await response.blob();
        return new File([blob], fileName, {
            type: blob.type,
            lastModified: new Date().getTime()
        });
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
}
export const extractFilename = (url) => url.split('/').pop();

export const processGalleryImage = async (image_path, add_renderlion_path = false) => {
    if (add_renderlion_path) {
        image_path = 'storage.renderlion.com/' + image_path;
    } else {
        image_path = image_path.split("https://");
        if (image_path[1]) {
            image_path = image_path[1];
        }
    }
    image_path = 'https://storage.googleapis.com/' + image_path;
    return await imageUrlToFile(image_path, extractFilename(image_path), false)
}

export const baseUrl = () => {
    return `${window.location.protocol}//${window.location.host}`
}

export const imglyConfig = () => {
    return {
        publicPath: `${baseUrl()}/js/imgly-background-removal/`, // path to the wasm files
        models: 'medium',
    }
}

export const isIOS = () => {
    let ua = navigator.userAgent || 'unknown';
    return /iPhone|iPod|iPad/.test(ua) || /CriOS/i.test(ua);
}

export const addStoragePath = file => {
    if (file.includes('storage.googleapis.com')) {
        return file;

    }
    if (file.startsWith('users')) {
        return 'https://storage.googleapis.com/storage.renderlion.com/' + file;
    }
    if (file.includes('storage.pixteller.com')) {
        file = file.split("https://");
        if (file[1]) {
            file = file[1];
        }
        return 'https://storage.googleapis.com/' + file;
    }
    return file;

}
export const isObject = obj => obj !== null && typeof obj === 'object';

export const downloadFromStorage = async file => {
    const file_name = extractFilename(file);
    file = addStoragePath(file)
    return await imageUrlToFile(file, file_name, false)
}

export const isVector=element=>{
    return Array.isArray(element) || isObject(element)
}

export const makeUniqueByProp = (arr, prop) => {
    const uniqueMap = new Map(arr.map(item => [item[prop], item]));
    return Array.from(uniqueMap.values());
};


export const replaceImages=(component_data,images,replace_images=null)=>{
    const { images: existingImages } =component_data;

    if (replace_images && existingImages) {
        images.forEach((newImage,index) => {
            const replaceImage=replace_images[index];
            const replaceImgIndex = existingImages.findIndex(existingImage => existingImage === replaceImage);
            if (replaceImgIndex !== -1) {
                existingImages[replaceImgIndex] = newImage;
            }else{
                existingImages.push(newImage);

            }
        });
    } else {
        existingImages.push(...images);
    }
}

export const handlePopupToggle=component=>{
    if (component.isTogglingPopUp) return;
    component.isTogglingPopUp = true;
    component.popup = !component.popup;

    // Reset the flag after handling
    component.$nextTick(() => {
        component.isTogglingPopUp = false;
    });
}




function removeHtmlTags(htmlString) {
    return htmlString.replace(/<[^>]*>/g, ""); // Remove HTML tags
}


function truncateTextContent(htmlString, maxLength) {
    const regex = /<[^>]*>|([^<]+)/g; // Matches HTML tags or text
    let totalLength = 0;
    const segments = [];

    // Extract all segments (text or HTML tags) and calculate total text length
    htmlString.replace(regex, (match, text) => {
        segments.push({text: match, isText: !!text});
        if (text) totalLength += text.length;
        return match; // Not used, but needed for replace to work correctly
    });

    // Calculate excess text length
    let excess = totalLength - maxLength;

    // If there's no need to truncate, return original string
    if (excess <= 0) {
        return htmlString;
    }

    // Truncate text from the end
    for (let i = segments.length - 1; i >= 0 && excess > 0; i--) {
        if (segments[i].isText) {
            let segmentLength = segments[i].text.length;
            if (segmentLength > excess) {
                // Truncate this segment partially
                segments[i].text = segments[i].text.substring(0, segmentLength - excess);
                excess = 0;
            } else {
                // Remove this segment entirely and update the excess
                segments[i].text = '';
                excess -= segmentLength;
            }
        }
    }

    // Reconstruct the HTML string
    return segments.map(s => s.text).join('');
}
export const checkTextAreaLength = (newValue, self, field, disabled_field=null,callback, with_data = false) => {
    if(isNull(newValue)){
        self.data[field] = newValue;
        return;
    }
    const text = removeHtmlTags(newValue);

    if (text.length <= 300) {
        return;
    }
    const editor = document.querySelector('.trumbowyg-editor');
    if (editor) editor.blur();
    if(disabled_field){
        self[disabled_field] = true;
    }
    let new_text=text.substring(0, 300);

    new_text=truncateTextContent(newValue,300);
    setTimeout(() => {
        if (with_data) {
            self.data[field] = new_text;

        } else {

            self[field] = new_text;

        }
        if(disabled_field) {
            self[disabled_field] = false;
        }
        callback(new_text);
    },10)
}


export const capitalizeFirstLetter=str=>{
   return str.charAt(0).toUpperCase() + str.slice(1);

}
