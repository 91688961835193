<template>
    <!--START OVERLAY -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div :class="{on:isSceneActive}"
             class="video_settings_button"
             @click="() => { $emit('setActiveChild',$options.name) ; toggleOverlay(true); }"
        >
            <strong>Overlay</strong> <span class="material-icons">arrow_drop_down</span>
        </div>

        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="price_type_block clr" style="padding-top: 6px;">
                <div class="price_type_left">
                    <div :class="{active:!data.overlay.active}"
                         class="open_popup_1"
                         @click="() => { $emit('setActiveChild','SpeedAndTiming') ; toggleOverlay(false); }"
                    >
                        <div>No Overlay <span class="material-icons" style="top: -3px;">blur_off</span></div>
                    </div>
                </div>
                <div class="price_type_right">
                    <div :class="{active:data.overlay.active}"
                         class="open_popup_1"
                    >
                        <div>With Overlay <span class="material-icons" style="top: -3px;">blur_on</span></div>
                    </div>
                </div>
            </div>

            <div v-if="data.overlay.active"
                 class="editor_block editor_block_logo"
            >
                <div class="editor_logo_container_left editor_scale_display">
                    <div class="editor_logo_display">
                        <!--START MINI VIDEO FOR BORDER-->
                        <!--FLORINXXX -> FOLLOW CLOSLY WHAT YOU NEED TO UPDATE IN STYLE -> THERE ARE A FEW-->
                        <div class="minivideo_container">
                            <div class="minivideo_bg"></div>
                            <div class="minivideo_block_overlay">
                                <!--FLORINXX -> .minivideo_overlay -> UPDATE OPACITY-->
                                <!--FLORINXX -> .minivideo_overlay -> CHECK THE SCALE IN STYLE-->
                                <div :style="{opacity: ((100-data.overlay.opacity)/100)}"
                                     class="minivideo_overlay">
                                    <!--                                     style="/*50 is the opacity value*/ opacity: calc((100 - 50) / 100);">-->
                                    <div class="minivideo_overlay_1"></div>
                                    <div class="minivideo_overlay_2"></div>
                                </div>
                            </div>
                        </div>
                        <!--END MINI VIDEO FOR BORDER-->
                    </div>
                </div>
                <div class="editor_logo_container_right">
                    <!--FLORINXXX -> ADD .mandatory WHEN VALUE IS INVALID-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <label class="mylabel_1" for="borderOpacity"><span>Opacity</span></label>
                        <custom-input
                            :id="'Opacity'"
                            :increment="5"
                            :max="100"
                            :min="0"
                            :unit="'%'"
                            :value="data.overlay.opacity"
                            @updateInput="updateOpacity"
                        />
                    </div>
                </div>
            </div>
            <!--ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD-->
        </div>
    </li>
    <!--END OVERLAY -> VIDEO SETTINGS-->
</template>

<script>
import CustomInput from '../../common/CustomInput.vue';

export default {
    name: "Overlay",
    components: {
        CustomInput
    },
    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        },
        selectedScenes: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        },
        isSceneActive() {
            const sceneIdName = this.$options.name.charAt(0).toLowerCase() + this.$options.name.slice(1) + 'IframeId';
            if (sceneIdName in this.selectedScenes) {
                return this.selectedScenes[sceneIdName] !== null;
            }
            return false;
        }
    },
    watch: {
        'selectedScenes.overlayIframeId': {
            handler(newValue) {
                if (newValue) {
                    this.$emit('toggleOverlay', true);
                } else {
                    this.$emit('toggleOverlay', false);
                }
            }
        }
    },
    methods: {
        toggleOverlay(value) {
            this.$emit('toggleOverlay', value);
        },
        updateOpacity(value) {
            this.$emit('updateOverlayOpacity', value);
        }
    }
}
</script>

<style scoped>

</style>
