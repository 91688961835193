<template>
    <div>
        <!--START FOREACH TITLE-BLOCK -> PRODUCTS / TEXT / ETC-->
        <div class="editor_foreach_block">
            <ol class="editor_foreach_list">
                <!--START FOREACH ITEM #1-->
                <li class="editor_foreach_item" v-for="(product,index) in products" :key="index">
                    <div class="editor_foreach_header">
                        <div class="editor_foreach_title">
                            <span>Product <em>#</em>{{ index + 1 }}</span>
                        </div>
                        <div class="editor_foreach_actions clr">
                            <!--Move Down-->
                            <div title="Move Down"
                                 class="efa_action efa_movedown"
                                 @click="moveProductDown(index)"
                            >
                                <div><span class="material-icons">keyboard_arrow_down</span></div>
                            </div>

                            <!--Move Up-->
                            <div title="Move Up"
                                 class="efa_action efa_moveup"
                                 @click="moveProductUp(index)"
                            >
                                <div><span class="material-icons">keyboard_arrow_up</span></div>
                            </div>

                            <!--Duplicate-->
                            <div title="Duplicate"
                                 class="efa_action efa_duplicate"
                                 @click="cloneProduct(index)"
                            >
                                <div><span class="material-icons">queue</span></div>
                            </div>

                            <!--Delete-->
                            <div title="Delete"
                                 class="efa_action efa_delete"
                                 @click="deleteProduct(index)"
                            >
                                <div><span class="material-icons">delete_forever</span></div>
                            </div>
                        </div>
                    </div>

                    <upload-multiple-images
                        :showHead="false"
                        :padding-top="24"
                        :title="'Product #'+(index+1)+' Photos'"
                        :index="index"
                        :uniqueID="uniqueID"
                        @addSelectedImages="addSelectedImages"
                        :deleted-image-index="deletedImageIndex[index]"
                        :selected-images="product.images"
                    />
                    <images-list
                        :images="product.images"
                        :index="index"
                        @deleteImage="deleteImage"
                        @moveImageLeft="moveImageLeft"
                        @moveImageRight="moveImageRight"
                        @updateImage="updateImage"
                    />
                    <!--START PRODUCT NAME-->
                    <div class="editor_block" style="padding-top: 12px;">
                        <choose-text-field
                            :showContentSuggestion="showProductContentSuggestion"
                            :indexForContentSuggestion="indexForContentSuggestion"
                            :activeTextType="product.activeTextType"
                            :type="'product'"
                            :headline="product.headline"
                            :text="product.text"
                            :index="index"
                            :contentSuggestion="contentSuggestionProduct"
                            @toggleShowContentSuggestion="toggleShowProductContentSuggestion"
                            @updateTheContentSuggestion="updateProductContentSuggestion"
                            @setActiveTextType="productSetActiveTextType"
                            @headlineChanged="productHeadlineChanged"
                            @textChanged="productTextChanged"
                        />
                    </div>
                    <!--END PRODUCT NAME-->


                    <!--START PRODUCT PRICING INPUTS-->
                    <!--FLORINXXX -> ADD .active ACCORDINGLY -> BASED ON IT THE TEXT INPUTS WILL DISPLAY-->
                    <div class="editor_block" style="">
                        <label class="mylabel_1" for=""><span>Product #{{ index + 1 }} Price</span></label>
                        <div class="price_type_block clr">
                            <div class="price_type_left">
                                <div class="open_popup_1"
                                     :class="{active:product.discounted}"
                                     @click="setDiscounted(true,index)"
                                >
                                    <div>Discounted <span class="material-icons" style="top: -3px;">mood</span></div>
                                </div>
                            </div>
                            <div class="price_type_right">
                                <div class="open_popup_1"
                                     :class="{active:!product.discounted}"
                                     @click="setDiscounted(false,index)"
                                >
                                    <div>No Discount <span class="material-icons" style="top: -3px;">mood_bad</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <product-prices :product="product" :index="index"
                                        :productNewPriceChanged="productNewPriceChanged"
                                        :productOldPriceChanged="productOldPriceChanged"
                                        :productDiscountChanged="productDiscountChanged"
                                        :productPriceChanged="productPriceChanged"

                        />
                        <div class="text_inspiration_block">
                            <div
                                class="button open_text_content_popup clr"
                                @click="toggleShowPricingSuggestion(index)"
                            >
                                <strong>Price Suggestion</strong>
                            </div>
                        </div>
                    </div>
                    <!--END PRODUCT PRICING INPUTS-->

                    <call-to-action-button
                        :index="index"
                        :showIndex="true"
                        :showContentSuggestion="showCallToActionContentSuggestion"
                        :indexForContentSuggestion="indexForCallToActionContentSuggestion"
                        :contentSuggestion="contentSuggestionCallToAction"
                        :callToActionButton="product.callToActionButton"
                        :callToActionText="product.callToActionText"
                        @toggleCallToActionButton="toggleCallToActionButton"
                        @textChanged="setCallToActionText"
                        @toggleShowContentSuggestion="toggleShowCallToActionContentSuggestion"
                        @updateTheContentSuggestion="updateTheContentSuggestionForCallToAction"
                    />
                </li>
                <!--END FOREACH ITEM #1-->


                <!--START FOREACH NEW ITEM #+++++++++-->
                <li class="editor_foreach_item" style="padding-top: 54px;">
                    <div class="editor_foreach_header">
                        <div class="editor_foreach_title">
                            <span>New Product?</span>
                        </div>
                    </div>
                    <div class="editor_block" style="padding-top: 0px;">
                        <div
                            class="open_popup_1"
                            @click="addNewProduct"
                        >
                            <div>Add Product Block <span class="material-icons" style="top: -3px;">add_box</span></div>
                        </div>
                    </div>
                </li>

                <!--END FOREACH NEW ITEM #+++++++++-->
            </ol>

        </div>
        <!--END FOREACH TITLE-BLOCK -> PRODUCTS / TEXT / ETC-->

        <pricing-suggestion
            v-if="showPricingSuggestion && null !== indexForPricingSuggestion"
            :pricingSuggestion="pricingSuggestion"
            :index="indexForPricingSuggestion"
            @toggleShowPricingSuggestion="toggleShowPricingSuggestion()"
            @updateThePricingSuggestion="updateThePricingSuggestion"
        />
    </div>
</template>

<script>
import 'trumbowyg/dist/ui/trumbowyg.css';
import UploadMultipleImages from "./UploadMultipleImages.vue";
import ImagesList from "./ImagesList.vue";
import PricingSuggestion from "../components/popups/PricingSuggestion.vue";
import ContentSuggestion from "../components/popups/ContentSuggestion.vue";
import ChooseTextField from "./ChooseTextField.vue";
import CallToActionButton from "./CallToActionButton.vue";
import ProductPrices from "../components/ProductPrices.vue";

export default {
    name: "ProductList",
    components: {
        ProductPrices,
        CallToActionButton,
        ChooseTextField,
        ContentSuggestion,
        PricingSuggestion,
        ImagesList,
        UploadMultipleImages,
    },
    props: {
        uniqueID: {
            type: String,
            default: null
        },
        products: {
            type: Array,
            default: () => ([])
        },
    },
    data: () => {

        return {
            deletedImageIndex: [],
            showProductContentSuggestion: false,
            productTemplate: {
                activeTextType: 1,
                headline: '<p>Enter Your Product Name</p>',
                text: '<p>Briefly describe your product. Highlight its key features and benefits.</p>',
                discounted: true,
                new_price: '<p>$399<sup>.99</sup></p>',
                old_price: '<p>$499<sup>.99</sup></p>',
                discount: '<p><strong>20% Off!</strong></p>',
                price: '<p><strong>75</strong><sup>.00</sup> CHF</p>',
                callToActionButton: false,
                callToActionText: '<p>Buy Now!</p>',
                images: []
            },
            indexForContentSuggestion: null,
            selectedIndex: null,
            contentSuggestionProduct: [
                {
                    type: 1,
                    headline: '<p>GlowMist Humidifier</p>',
                    text: '<p>A sleek, ultrasonic humidifier with ambient LED lighting designed to enhance your home air quality and mood.</p>'
                },
                {
                    type: 3,
                    headline: '<p>FlashFreeze Mini Fridge</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>EcoCrunch Kitchen Composter</p>',
                    text: '<p>Turn your food scraps into nutrient-rich soil with this electric, odorless, indoor composting gadget.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Kiss Dry Lips Goodbye</strong></p>',
                    text: '<p>LushLips Hydrating Lipstick Now on Special!</p>'
                },
                {
                    type: 1,
                    headline: '<p>AquaPure Smart Water Filter</p>',
                    text: '<p>A smart water filtration system that connects to your faucet.</p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Ultimate Streaming Experience</em></p>',
                    text: '<p>StreamKing 4K Android TV Box - Hot Deal!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>ZenWave Sleep Headphones</strong></p>',
                    text: '<p>Soft, comfortable headphones designed for sleeping, featuring noise-canceling technology!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>WaveMaster Waterproof Speaker!</strong> Sound that Swims with You.</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>FitPulse Mini Massage Gun</p>',
                    text: '<p>A compact, portable massage gun to soothe muscle aches on-the-go!</p>'
                },
                {
                    type: 1,
                    headline: '<p>SolarBrite String Lights</p>',
                    text: '<p>Outdoor solar-powered LED string lights that add a cozy glow to any space without the need for electrical outlets.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>PureBrew Single Serve Coffee Maker</strong></p>',
                    text: '<p>An innovative coffee maker that offers the convenience of single-serve pods.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>FrostTech Air Conditioner</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>Chill in Style!</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><em>TravelEase Pro Backpack</em></p>',
                    text: '<p>An ergonomically designed backpack with built-in USB charging, anti-theft compartments, and a water-resistant.</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>PixelPro Smartphone</strong></p>',
                    text: '<p>A high-resolution camera phone with AI capabilities.</p>'
                },
                {
                    type: 3,
                    headline: '<p>PixelView 4K Monitor</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>FlexStep Sneakers</p>',
                    text: '<p>Perfect for runners seeking comfort and support on diverse terrains.</p>'
                },
                {
                    type: 1,
                    headline: '<p>BeatBlitz Wireless Earbuds</p>',
                    text: '<p>Ideal for active lifestyles and fitness routines.</p>'
                },
                {
                    type: 1,
                    headline: '<p>LushLips Hydrating Lipstick</p>',
                    text: '<p>A long-wear lipstick that combines rich color payoff with the hydration of a lip balm.</p>'
                },
                {
                    type: 3,
                    headline: '<p>InfinityEdge Tablet - Boundless Creativity.</p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>Ultra-Sharp, Ultra-Productive</strong></p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p><strong>AeroDrone 4K Quadcopter</strong></p>',
                    text: '<p>Featuring 4K video capabilities and advanced stability controls.</p>'
                },
                {
                    type: 1,
                    headline: '<p>PowerPlay Gaming Console</p>',
                    text: '<p>4K gaming support, and a VR-ready setup for an immersive gaming experience.</p>'
                },
                {
                    type: 3,
                    headline: '<p>EcoSnap Solar Charger - <strong>Power Up, Green Style</strong></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong><em>SkyHigh Drone Pro</em></strong> - Elevate Your Shots.</p>',
                    text: null
                },
                {
                    type: 2,
                    headline: '<p>NightBliss Sleep Mask</p>',
                    text: '<p>Embrace the Dark, Find Your Light.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>EcoTrek Smartwatch</strong></p>',
                    text: '<p>GPS tracking, heart rate monitoring, and a solar-powered battery.</p>'
                },
                {
                    type: 1,
                    headline: '<p>SilkSkin Laser Hair Remover</p>',
                    text: '<p>Pain-Free way to achieve smooth, hairless skin without the salon price tag.</p>'
                },
                {
                    type: 1,
                    headline: '<p>StreamKing 4K Android TV Box</p>',
                    text: '<p>Transform any TV into a smart TV with this 4K Android box</p>'
                },
                {
                    type: 1,
                    headline: '<p>QuickCharge Portable Power Station</p>',
                    text: '<p>A high-capacity, fast-charging power station to keep your devices powered.</p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Capture Life Moments</em></p>',
                    text: '<p>Save on the PixelPro Smartphone Today!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Run Further, Feel Lighter</strong></p>',
                    text: '<p>FlexStep Sneakers on Sale Now!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Luxury at Your Fingertips</strong></p>',
                    text: '<p>VelvetTouch Smart Curtains - Elegance Now More Affordable!</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>CurlQueen Hair Wand</strong> - Locks that Rock.</p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>PureAir Compact Purifier - Breathe Easy, Live Pure.</strong></p>'
                },
                {
                    type: 1,
                    headline: '<p>Dynamic Workouts, Dynamic Savings</p>',
                    text: '<p>FlexiBand Resistance Set - Strengthen Your Body, Not Expenses!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Illuminate Your Beauty</p>',
                    text: '<p>GlamGlow Highlighter Palette at a Glowing Price!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Soundtrack Your Journey</p>',
                    text: '<p>BeatBlitz Wireless Earbuds - Discounted for a Limited Time!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>PetalSoft Electric Makeup Brush Cleaner</strong></p>',
                    text: '<p>Cleans and dries makeup brushes in seconds, maintaining brush health and ensuring hygienic makeup application.</p>'
                },
                {
                    type: 3,
                    headline: '<p><strong>Cool Snacks, Hot Style!</strong></p>',
                    text: null
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>LuminaDesk LED Lamp</strong> - Light Up Your Workspace</p>'
                },
                {
                    type: 1,
                    headline: '<p>Elevate Your View</p>',
                    text: '<p>AeroDrone 4K Quadcopter - Exclusive Sale!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Home Comfort in Every Step</p>',
                    text: '<p>WalkCloud Memory Foam Slippers - Buy More, Save More!</p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Groomed to Perfection</em></p>',
                    text: '<p>TrimKing Beard Trimmer - Precision Meets Affordability!</p>'
                },
                {
                    type: 2,
                    headline: '<p><em>Define Your Look</em></p>',
                    text: '<p>LuxeLiner Liquid Eyeliner Now on Offer!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Level Up Your Game</p>',
                    text: '<p>PowerPlay Gaming Console at a Winning Price!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Adventure Awaits</strong></p>',
                    text: '<p>Get Your EcoTrek Smartwatch on Sale Today!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Sleek Skin, Sleek Savings</p>',
                    text: '<p>SilkSkin Laser Hair Remover at a Special Discount!</p>'
                },
                {
                    type: 3,
                    headline: null,
                    text: '<p>FitTrack Smart Scale - Know Your Health Better</p>'
                },
                {
                    type: 3,
                    headline: '<p><em>QuikCharge Wireless Stand</em></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>Stay Powered, Stay Prepared</p>',
                    text: '<p>QuickCharge Portable Power Station at an Exclusive Price!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Step Smart with Technology</strong></p>',
                    text: '<p>ActiveSole Smart Insoles Now on Promo!</p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Revolutionize Your Skincare Routine</em></p>',
                    text: '<p>Get Your SkinPure Face Spatula with a Hot Discount!</p>'
                },
                {
                    type: 3,
                    headline: '<p>StrideLight Running Shoes</p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p>GlossBoss Lip Shine</p>',
                    text: '<p>Pout Perfection in a Swipe.</p>'
                },
                {
                    type: 3,
                    headline: '<p><em>ZenScent Aroma Diffuser</em></p>',
                    text: null
                },
                {
                    type: 3,
                    headline: '<p><strong>Tranquility, One Puff at a Time!</strong></p>',
                    text: null
                },
                {
                    type: 1,
                    headline: '<p><strong>Refined Reading</strong></p>',
                    text: '<p>KindleClarity E-Reader - Unmatched Clarity at an Unbeatable Price!</p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Cook Smart, Eat Smart</em></p>',
                    text: '<p>ChefMate Smart Oven - Revolutionary Cooking on Sale!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Energize Your Day</p>',
                    text: '<p>JavaJolt Coffee Grinder - Wake Up to Fresh Deals!</p>'
                },
                {
                    type: 2,
                    headline: '<p>Breathe Clean, Live Pure</p>',
                    text: '<p><strong>AirGenius Purifier</strong> - Purify Your Space for Less!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Step into the Future</p>',
                    text: '<p>GlideTech Hoverboard - Glide at Great Discounts!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Secure Your World</p>',
                    text: '<p><strong>SafeHome Security Cam - Your Peace of Mind, Now on Sale!</strong></p>'
                },
                {
                    type: 2,
                    headline: '<p>WalkCloud Memory Foam Slippers</p>',
                    text: '<p>Ultra-comfortable slippers with memory foam soles that mold to your feet.</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>LuxeLiner Liquid Eyeliner</strong></p>',
                    text: '<p>A smudge-proof, precision liquid eyeliner for creating flawless winged or tight-lined looks.</p>'
                },
                {
                    type: 1,
                    headline: '<p>Crafted for the Craftsmen</p>',
                    text: '<p><strong>ToolMaster 5-in-1 Drill - Build More, Save More!</strong></p>'
                },
                {
                    type: 1,
                    headline: '<p><em>Unwind in Style</em></p>',
                    text: '<p>SereneGlow LED Candles - Set the Mood for Less!</p>'
                },
                {
                    type: 1,
                    headline: '<p><strong>Listen in HD</strong></p>',
                    text: '<p>ClearSound Headphones for High-Def Audio, Low Prices!</p>'
                },
                {
                    type: 2,
                    headline: '<p><strong>Stay Cool, Anywhere</strong></p>',
                    text: '<p>BreezeBox Portable Fan for Cool Deals Blowing In!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Play in HD</p>',
                    text: '<p>MegaPixel Gaming Monitor to Immerse Yourself in Savings!</p>'
                },
                {
                    type: 1,
                    headline: '<p>Blend it Right</p>',
                    text: '<p>SmoothMix Blender - Blend Smoothly with Sweet Deals!</p>'
                }
            ],
            pricingSuggestion: [
                {
                    discounted: true,
                    new_price: '<p>$399<sup>.99</sup></p>',
                    old_price: '<p>$499<sup>.99</sup></p>',
                    discount: '<p><strong>20% Off!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p><sup>¥</sup>9,99</p>',
                    old_price: '<p><sup>¥</sup>19,98</p>',
                    discount: '<p>50% Savings!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€<strong>99</strong><sup>.99</sup></p>',
                    old_price: '<p>€<strong>199</strong><sup>.99</sup></p>',
                    discount: '<p>50% Discount Offer!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$<strong>210</strong></p>',
                    old_price: '<p>$<strong>350</strong></p>',
                    discount: '<p><strong>40% Discount!</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p><strong>75</strong><sup>.00</sup> CHF</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>R$<strong>180</strong></p>',
                    old_price: '<p>R$<strong>300</strong></p>',
                    discount: '<p>Enjoy a 30% Discount!</p>'
                },
                {
                    discounted: false,
                    price: '<p>HK$<strong>258</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p><sup>$</sup><strong>110</strong></p>',
                    old_price: '<p><sup>$</sup><strong>200</strong></p>',
                    discount: '<p>45% OFF Today!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>₹<strong>3200</strong></p>',
                    old_price: '<p>₹<strong>4000</strong></p>',
                    discount: '<p><strong>Save 20%!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$89<sup>.95</sup></p>',
                    old_price: '<p>$149<sup>.95</sup></p>',
                    discount: '<p><strong>40% Off!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$65</p>',
                    old_price: '<p>$100</p>',
                    discount: '<p><strong>35% Discount!</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p>$399<sup>.99</sup></p>'
                },
                {
                    discounted: false,
                    price: '<p>380<sup> SAR</sup></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$75<sup>.00</sup></p>',
                    old_price: '<p>$125<sup>.00</sup></p>',
                    discount: '<p>40% Off - Limited Time Offer!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€<strong>105</strong><sup>.30</sup></p>',
                    old_price: '<p>€<strong>117</strong><sup>.00</sup></p>',
                    discount: '<p><strong>10% OFF Deal!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p><strong>800</strong><sup>SEK</sup></p>',
                    old_price: '<p><strong>1,000</strong><sup>SEK</sup></p>',
                    discount: '<p>20% Off Today!!!</p>'
                },
                {
                    discounted: false,
                    price: '<p><strong>550</strong> Ron</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€<strong>52</strong><sup>.49</sup></p>',
                    old_price: '<p>€<strong>74</strong><sup>.99</sup></p>',
                    discount: '<p><strong>30% Price Cut!</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p>$35<sup>.99</sup></p>'
                },
                {
                    discounted: false,
                    price: '<p><strong>39<sup>.99</sup></strong> CHF</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€<strong>399<sup>.99</sup></strong></p>',
                    old_price: '<p>€<strong>499<sup>.99</sup></strong></p>',
                    discount: '<p>Get 20% Off Today!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p><sup>NOK</sup><strong>750</strong></p>',
                    old_price: '<p><sup>NOK</sup><strong>1,000</strong></p>',
                    discount: '<p><strong>Get 30% Off Today!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$24<sup>.99</sup></p>',
                    old_price: '<p>$49<sup>.99</sup></p>',
                    discount: '<p>50% Discount Offer!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€129</p>',
                    old_price: '<p>€199</p>',
                    discount: '<p><strong>Save 35%!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>£30.60</p>',
                    old_price: '<p>£34.00</p>',
                    discount: '<p><strong>10% Price Reduction!</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p><sup>SEK</sup>550</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€<strong>45</strong></p>',
                    old_price: '<p>€<strong>75</strong></p>',
                    discount: '<p>40% Off Sale!</p>'
                },
                {
                    discounted: false,
                    price: '<p>$105</p>'
                },
                {
                    discounted: false,
                    price: '<p><strong>120</strong>AU$</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>CA$<strong>79<sup>.99</sup></strong></p>',
                    old_price: '<p>CA$<strong>99<sup>.99</sup></strong></p>',
                    discount: '<p>Get 20% Off Today!</p>'
                },
                {
                    discounted: false,
                    price: '<p>₹1500</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>650<sup>ZAR</sup></p>',
                    old_price: '<p>1,000<sup>ZAR</sup></p>',
                    discount: '<p><strong>35% Savings!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>£<strong>22<sup>.10</sup></strong></p>',
                    old_price: '<p>£<strong>26<sup>.00</sup></strong></p>',
                    discount: '<p><strong>15% Discount Offer!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>€210</p>',
                    old_price: '<p>€350</p>',
                    discount: '<p>40% OFF - Limited Time Offer!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>R$350<sup>.00</sup></p>',
                    old_price: '<p>R$500<sup>.00</sup></p>',
                    discount: '<p>30% OFF Deal!!</p>'
                },
                {
                    discounted: false,
                    price: '<p>DKK<strong>460<sup>.00</sup></strong></p>'
                },
                {
                    discounted: false,
                    price: '<p>$550</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>NZ$<strong>55<sup>.00</sup></strong></p>',
                    old_price: '<p>NZ$<strong>100<sup>.00</sup></strong></p>',
                    discount: '<p><strong>Get a 30% Discount Now!</strong></p>'
                },
                {
                    discounted: true,
                    new_price: '<p>$<strong>199</strong><sup>.99</sup></p>',
                    old_price: '<p>$<storng>285</strong><sup>.00</sup></p>',
                    discount: '<p><strong>30% Price Cut!</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p>$<strong>1.200</strong></p>'
                },
                {
                    discounted: false,
                    price: '<p>100,000KRW</p>'
                },
                {
                    discounted: true,
                    new_price: '<p>¥<strong>7,500</strong></p>',
                    old_price: '<p>¥<strong>15,000</strong></p>',
                    discount: '<p>Get 50% off!</p>'
                },
                {
                    discounted: true,
                    new_price: '<p><strong>24<sup>.99</sup></strong>€</p>',
                    old_price: '<p><strong>49<sup>.99</sup></strong>€</p>',
                    discount: '<p>50% Discount Offer!</p>'
                },
                {
                    discounted: false,
                    price: '<p>$45</p>'
                },
                {
                    discounted: false,
                    price: '<p><strong>340</strong> AED</p>'
                },
                {
                    discounted: false,
                    price: '<p>$<strong>89<sup>.99</sup></strong></p>'
                }
            ],
            showPricingSuggestion: false,
            indexForPricingSuggestion: null,
            showCallToActionContentSuggestion: false,
            contentSuggestionCallToAction: [
                {
                    type: 4,
                    headline: null,
                    text: '<p>Enjoy</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>See the Unseen</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Act Now</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Experience</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Become a Member</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Limited Time Offer</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover the Deal</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Experience It Now</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Claim Your Discount</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Witness the Magic</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Play and Learn</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Today Only</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Connect</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Discover More</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Order Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get the Offer</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get the Insights</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Shop the Trend</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Limited Offer</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Join the Fun</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Start Saving</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Buy Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Unbeatable Offers</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Unlock the Magic</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Buy More, Save More</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Discover Discounts</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Feel the Excitement</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Explore the Sale</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Ready, Set, Shop!</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Exclusive Deals</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Exclusive Access</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Indulge Yourself</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Grab the Deal</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>View Collection</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Ignite</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Start Your Journey</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Ignite Curiosity</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Get Yours Now</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Delve In</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get Exclusive Access</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Reveal the Secret</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Treat Yourself</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Explore Now</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Savings Inside</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Snag the Offer</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Seize the Deal</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Let\'s Get Started</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>View the Collection</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Be Inspired</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Don\'t Miss Out</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Explore the Unknown</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Reveal the Offer</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Find Your Style</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong><em>Shop Smart</em></strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Get It First</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Join the Adventure</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><em>Find Out More</em></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Dive Deeper</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Hot Deals Inside</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Savings Await</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Last Chance</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Unlock Savings</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>See the Deals</p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p><strong>Shop Now</strong></p>',
                },
                {
                    type: 4,
                    headline: null,
                    text: '<p>Be the First</p>',
                },
            ],
            indexForCallToActionContentSuggestion: null,
        }
    },
    methods: {
        moveProductDown(index) {
            this.$emit('moveProductDown', index)
        },
        moveProductUp(index) {
            this.$emit('moveProductUp', index)
        },
        cloneProduct(index) {
            this.$emit('cloneProduct', index)
        },
        deleteProduct(index) {
            this.$emit('deleteProduct', index)
        },
        toggleShowProductContentSuggestion(index) {
            this.showProductContentSuggestion = !this.showProductContentSuggestion;
            if (this.showProductContentSuggestion) {
                this.indexForContentSuggestion = index;
            } else {
                this.indexForContentSuggestion = null;
            }
            this.$emit('togglePopUp')
        },
        updateProductContentSuggestion(data, index) {
            this.$emit('updateProductContentSuggestion', data, index)
        },
        productSetActiveTextType(value, index) {
            this.$emit('productSetActiveTextType', value, index)
        },
        productHeadlineChanged(event, index) {
            this.$emit('productHeadlineChanged', event.target.value, index)
        },
        productTextChanged(event, index) {
            this.$emit('productTextChanged', event.target.value, index)
        },
        setDiscounted(state, index) {
            this.$emit('setDiscounted', state, index);
        },
        productNewPriceChanged(event, index) {
            this.$emit('productNewPriceChanged', event.target.value, index)
        },
        productOldPriceChanged(event, index) {
            this.$emit('productOldPriceChanged', event.target.value, index)
        },
        productDiscountChanged(event, index) {
            this.$emit('productDiscountChanged', event.target.value, index)
        },
        productPriceChanged(event, index) {
            this.$emit('productPriceChanged', event.target.value, index)
        },
        addSelectedImages(images, index, replace_images = null) {
            this.$emit('addSelectedImages', images, index, replace_images)
        },
        deleteImage(imageIndex, productIndex) {
            this.deletedImageIndex[productIndex] = imageIndex === this.deletedImageIndex[productIndex] ? String(imageIndex) : imageIndex
            this.$emit('deleteImage', imageIndex, productIndex)
        },
        moveImageLeft(imageIndex, productIndex) {
            this.$emit('moveImageLeft', imageIndex, productIndex)
        },
        moveImageRight(imageIndex, productIndex) {
            this.$emit('moveImageRight', imageIndex, productIndex)
        },
        updateImage(image, imageIndex, productIndex) {
            this.$emit('updateImage', image, imageIndex, productIndex)
        },
        toggleShowPricingSuggestion(index) {
            this.showPricingSuggestion = !this.showPricingSuggestion;
            if (index !== null) {
                this.indexForPricingSuggestion = index;
            } else {
                this.indexForPricingSuggestion = null;
            }
            this.$emit('togglePopUp')
        },
        updateThePricingSuggestion(pricing, index) {
            this.setDiscounted(pricing.discounted, index)
            if (pricing.price) {
                this.$emit('productPriceChanged', pricing.price, index)
            } else {
                this.$emit('productNewPriceChanged', pricing.new_price, index)
                this.$emit('productOldPriceChanged', pricing.old_price, index)
                this.$emit('productDiscountChanged', pricing.discount, index)
            }
        },
        toggleCallToActionButton(index) {
            this.$emit('toggleCallToActionButton', index)
        },
        setCallToActionText(event, index) {
            this.$emit('setCallToActionText', event.target.value, index);
        },
        toggleShowCallToActionContentSuggestion(index) {
            this.$emit('togglePopUp')
            this.showCallToActionContentSuggestion = !this.showCallToActionContentSuggestion;

            if (this.showCallToActionContentSuggestion) {
                this.indexForCallToActionContentSuggestion = index;
            } else {
                this.indexForCallToActionContentSuggestion = null;
            }
        },
        updateTheContentSuggestionForCallToAction(selectedContent, index = null) {
            this.$emit('setCallToActionText', selectedContent.text, index);
        },
        addNewProduct() {
            this.$emit('addNewProduct', this.productTemplate)
        }
    }
}
</script>

<style scoped>

</style>
