<template>
    <div
        class="vid_main_container vid_iframe_container">
        <iframe ref="vidIframe" class="vid_iframe" :key="aspectRatio"
                :src="iframeUrl">
        </iframe>
    </div>
</template>
<script>
export default {
    name: "AnimationIframe",
    props: {
        aspectRatio: {
            type: String,
            default: 'square',
        },
        animationName: {
            type: String,
            default: '',
        },
        windowWidth: {
            type: Number,
            default: 1024
        }
    },
    computed: {
        iframeUrl() {
            let scale = this.windowWidth > 588 ? 540 : 320;
            if (this.animationName.includes('temporary')) {
                return `player?dataID=${this.animationName}&video_display=preview&video_data=partial&scale=${scale}&video_size=${this.aspectRatio}`;
            } else {
                return `player/${this.animationName}?video_display=preview&video_data=partial&scale=${scale}&video_size=${this.aspectRatio}`;
            }
        }
    }
}
</script>
<style>
.is-hidden {
    display: none;
}
</style>
