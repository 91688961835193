<template>
    <!--START SOUND -> VIDEO SETTINGS-->
    <!--FLORINXXX -> ADD .active TO OPEN THE SETTINGS BLOCK-->
    <li :class="{active:isClassActive}"
        class="video_settings_item"
    >
        <div :class="{on:isSoundOn}"
             class="video_settings_button"
             @click="$emit('setActiveChild',$options.name)"
        >
            <strong>Sound</strong> <span class="material-icons">arrow_drop_down</span>
        </div>
        <div class="video_settings_content">
            <!--STARTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT-->
            <div class="price_type_block clr" style="padding-top: 6px;">
                <div class="price_type_left">
                    <div :class="{active:!isActiveSound}"
                         class="open_popup_1"
                         @click="() => { $emit('setActiveChild','SpeedAndTiming') ; setNoSound(); }"
                    >
                        <div>No Sound <span class="material-icons" style="top: -2px;">volume_off</span></div>
                    </div>
                </div>
                <div class="price_type_right">
                    <div :class="{active:isActiveSound}"
                         class="open_popup_1"
                    >
                        <div>With Sound <span class="material-icons" style="top: -2px;">volume_up</span></div>
                    </div>
                </div>
            </div>
            <div class="clear" style="height: 18px;"></div>

            <!--START MUSIC PLAYER-->
            <!--FLORINXXX -> ADD .active IF TRACK (MY OR GALLERY) IS SELECTED-->
            <div :class="{active:isActiveSound}"
                 class="music_player_block"
            >
                <!--HERE IS PREVIEWS TRACK + PLAY / PAUSE + NEXT TRACK + TRACK NAME-->
                <!--CLASSES: .active / .none-->
                <div class="music_player_top">
                    <!--PREVIEWS TRACK BUTTON-->
                    <div :class="{active: canPlayPrevious}"
                         class="track_previews_button"
                         title="Previews Track"
                         @click="playPreviousSong"
                    >
                        <span class="material-icons">skip_previous</span>
                    </div>
                    <!--PLAY BUTTON-->
                    <div :class="{active:isActiveSound, none:player.isPlaying}" class="track_play_button"
                         title="Play"
                         @click="toggleAudio()"
                    >
                        <span class="material-icons">play_arrow</span>
                    </div>
                    <!--PAUSE BUTTON-->
                    <div :class="{none:!player.isPlaying}" class="track_pause_button active"
                         title="Pause"
                         @click="toggleAudio()"
                    >
                        <span class="material-icons">pause</span>
                    </div>
                    <!--NEXT TRACK BUTTON-->
                    <div :class="{active: canPlayNext }"
                         class="track_next_button"
                         title="Next Track"
                         @click="playNextSong"
                    >
                        <span class="material-icons">skip_next</span>
                    </div>
                    <!--TRACK NAME-->
                    <div class="track_symbol material-icons">headset</div>
                    <div class="track_name">
                        <div class="track_name_title">
                            <!--REMOVE Amy Lynn & the Honey Men-->
                            {{ playSong.title }}
                        </div>
                    </div>
                    <audio
                        ref="player"
                        class="none"
                    >
                        <source :src="songLink" type="audio/mpeg"/>
                    </audio>
                </div>

                <!--HERE IS BAR + CURRENT TIME + TOTAL TRACK TIME-->
                <div class="music_player_bottom">
                    <div class="music_player_bar">
                        <div :style="{width:playedPercentage+'%'}"
                             class="music_player_progress_bar"
                        ></div>
                        <div class="music_progress_block">
                            <div v-for="index in 50"
                                 :key="index"
                                 :class="['music_progress_button_'+index]"
                                 class="music_progress_button"
                                 @click="setCurrentTime(index*2)"
                            ><span>{{ index * 2 }}%</span></div>
                        </div>
                    </div>
                    <div v-if="isActiveSound" class="music_time music_current_time">{{ playedTime }}</div>
                    <div v-if="isActiveSound" class="music_time music_total_time" v-html="totalTime()"></div>
                </div>
            </div>
            <!--END MUSIC PLAYER-->

            <!--START UPLOAD MUSIC-->
            <div class="music_upload_block">
                <div :class="{mandatory:errorMessage}" class="editor_block"
                     style="padding-top: 24px;"
                >
                    <label class="mylabel_1" for=""><span>Upload Music as MP3</span></label>

                    <!--FLORINXXX -> ADD .active WHEN UPLOADING-->
                    <!--FLORINXXX -> ADD .error WHEN FILETYPE IS WRONG OR OTHER ERRORS ON UPLOADING-->
                    <div :class="{error: errorMessage , active:uploading }"
                         class="my_upload_block"
                    >
                        <div class="my_upload_content">
                            <div class="my_upload_content_inner">
                                <div class="my_upload_content_left">
                                    <div class="my_upload_content_txt my_upload_content_normal">
                                        Use high-quality MP3 file<br/>
                                        for best results.
                                    </div>
                                    <div class="my_upload_content_txt my_upload_content_error">
                                        Error: Invalid file type!<br/>
                                        Please try again.
                                    </div>
                                </div>
                                <div class="my_upload_content_right">
                                    <div class="my_upload_content_button">Upload</div>
                                </div>
                                <input ref="userSound"
                                       accept=".mp3"
                                       class="my_upload_content_input"
                                       multiple
                                       name="songs"
                                       type="file"
                                       @change="uploadSound"
                                >
                            </div>
                            <div class="my_upload_content_inner_uploading">
                                <div class="my_upload_content_left">
                                    <div class="my_upload_content_txt">
                                        Keep calm!<br/>
                                        We are processing your music.
                                    </div>
                                </div>
                                <div class="my_upload_content_right">
                                    <div class="lds-ring lds-ring5">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--END UPLOAD MUSIC-->

            <!--START MY MUSIC-->
            <!--FLORINXXX -> ADD .none IF USER HAS NO MP4 FILES UPLOADED-->
            <!--FLORINXXX -> THE FILE CAN BE ALSO DELETED-->
            <div v-if="mySongs.list.length" class="music_my_block">
                <div class="editor_block" style="padding-top: 24px;">
                    <label class="mylabel_1" for=""><span>My Music</span></label>
                    <div class="music_playlist">
                        <ol
                            ref="listElSoundGallery"
                            style="overflow-y: auto;"
                            @scroll="onScrollSongsGallery"
                        >
                            <li v-for="(song,index) in mySongs.list" :key="index">
                                <div :class="{active : songLink.indexOf(song.src) !== -1}"
                                     class="music_button"
                                     @click="playMySong(song,index)"
                                >
                                    <strong>My Track #{{ song.generatedId }}</strong>
                                    <div class="delete_sound_button" @click="deleteMySong(index,song.id)">Delete <span
                                        class="material-icons">close</span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="!mySongs.finished"
                                :class="{active: mySongs.loading}"
                                class="music_list_loader"
                            >
                                <div class="lds-ring lds-ring4">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!--END MY MUSIC-->

            <!--START MUSIC GALLERY-->
            <!--FLORINXXX -> DOWNLOAD FROM HERE
                -> https://studio.youtube.com/channel/UC4vUWooxUHkjwCP69HnPRqg/music
                -> https://buffer.com/library/background-music-video/
                -> MANY MORE
            -->
            <div class="music_gallery_block">
                <div class="editor_block" style="padding-top: 24px;">
                    <label class="mylabel_1" for=""><span>Music Gallery</span></label>
                    <ul class="music_type_list clr">
                        <li v-for="category in songGallery.categories"
                            :key="category.slug"
                            @click="setActiveCategory(category.id)"
                        >
                            <div :class="{active: songGallery.activeCategory==category.id}"
                                 class="music_type_button"
                            >
                                {{ category.name }}
                            </div>
                        </li>
                    </ul>

                    <div v-if="songGallery.list.length" class="music_playlist">
                        <ol
                            ref="listEl"
                            style="overflow-y: auto;"
                            @scroll="onDivScroll"
                        >
                            <li v-for="(song,index) in songGallery.list" :key="index">
                                <div :class="{active : songLink.indexOf(song.src) !== -1}"
                                     class="music_button"
                                     @click="playSongFromGallery(song,index)"
                                >
                                    <strong>{{ song.title }}</strong>
                                </div>
                            </li>
                            <li v-if="!songGallery.finished"
                                :class="{active: songGallery.loading}"
                                class="music_list_loader"
                            >
                                <div class="lds-ring lds-ring4">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!--END MUSIC GALLERY-->
        </div>
    </li>
    <!--END SOUND -> VIDEO SETTINGS-->
</template>

<script>
import {onBeforeUnmount} from "vue";

export default {
    name: "Sound",
    // Your existing component options...

    props: {
        activeChild: {
            type: String,
            default: 'SpeedAndTiming'
        },
        initialSong: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return ({
            mySongs: {
                list: [],
                page: 1,
                loading: false,
                finished: false
            },
            playSong: {
                type: Object,
                default() {
                    return {}
                }
            },
            songGallery: {
                list: [],
                categories: [],
                activeCategory: null,
                page: 1,
                loading: false,
                finished: false
            },
            player: {
                playbackTime: 0,
                audioDuration: 100,
                audioLoaded: false,
                isPlaying: false,
                listenerActive: false,
            },
            playCategory: {
                mySongs: false,
                index: 0,
            },
            errorMessage: null,
            uploading: false
        });
    },
    created(){
        this.setPlaySong(this.initialSong);
    },
    computed: {
        isClassActive() {
            return this.$options.name === this.activeChild;
        },
        isSoundOn() {
            // Check if object is not empty
            if (Object.keys(this.playSong).length > 0) {
                // Check if any property is not null
                return this.playSong.src !== null;
            }
            return false;
        },
        isActiveSound() {
            if (Object.keys(this.playSong).length === 0 || this.playSong.src === null) {
                return false;
            }
            return true;
        },
        playedTime() {
            return this.convertTime(this.player.playbackTime);
        },
        playedPercentage() {
            return (this.player.playbackTime / this.player.audioDuration) * 100;
        },
        songLink() {
            return 'https://storage.renderlion.com/' + this.playSong.src;
        },
        canPlayPrevious() {
            if (this.isActiveSound && this.playCategory.mySongs && this.playCategory.index > 0) {
                return true;
            }
            return false;
        },
        canPlayNext() {
            if (this.isActiveSound && this.playCategory.mySongs && (this.playCategory.index < this.mySongs.list.length - 1)) {
                return true;
            } else if (this.isActiveSound && !this.playCategory.mySongs && (this.playCategory.index < this.songGallery.list.length - 1)) {
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.loadUserSongs();
        this.loadSongCategories();
    },
    watch: {
        'player.isPlaying': function () {
            //to work with changes in someOtherProp
            if (this.player.isPlaying) {
                let audio = this.$refs.player;
                //prevent starting multiple listeners at the same time
                if (!this.player.listenerActive) {
                    this.player.listenerActive = true;
                    //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
                    audio.addEventListener("timeupdate", this.playbackListener);
                }
            }
        },
        'player.playbackTime': function (newValue, oldValue) {
            let audio = this.$refs.player;
            let diff = Math.abs(this.player.playbackTime - audio.currentTime);
            //Throttle synchronization to prevent infinite loop between playback listener and this watcher
            if (diff > 0.01) {
                audio.currentTime = this.player.playbackTime;
            }
        },
        'activeChild': function (newValue, oldValue) {
            if (newValue !== 'Sound' && this.player.isPlaying) {
                this.toggleAudio();
            }
        },
        'initialSong':function (newValue, oldValue) {
            this.setPlaySong(newValue);

        }
    },
    methods: {
        setPlaySong(initialSong){
            if(initialSong){
                if(Array.isArray(initialSong)){
                    this.playSong=initialSong[0]
                    this.$emit('updateActiveSong', this.playSong);

                    return;
                }
                this.playSong=this.initialSong;


            }
        },
        //Convert audio current time from seconds to min:sec display
        convertTime(seconds) {
            const format = val => `0${Math.floor(val)}`.slice(-2);
            let hours = seconds / 3600;
            let minutes = (seconds % 3600) / 60;
            return [minutes, seconds % 60].map(format).join(":");
        },
        toggleAudio() {
            if (!this.isActiveSound) {
                return;
            }
            let audio = this.$refs.player;

            if (audio.paused) {
                audio.play();
                this.player.isPlaying = true;
            } else {
                audio.pause();
                this.player.isPlaying = false;
            }
            this.errorMessage = null;
        },
        //Show the total duration of audio file
        totalTime() {
            let audio = this.$refs.player;
            if (audio) {
                let seconds = audio.duration;
                this.player.audioDuration = seconds;
                if (this.convertTime(seconds) == 'aN:aN') {
                    return '00:00';
                } else {
                    return this.convertTime(seconds);
                }
            } else {
                return '00:00';
            }
        },
        playbackListener(e) {
            let audio = this.$refs.player;
            //Sync local 'playbackTime' var to audio.currentTime and update global state
            this.player.playbackTime = audio.currentTime;

            //Add listeners for audio pause and audio end events
            audio.addEventListener("ended", this.endListener);
            audio.addEventListener("pause", this.pauseListener);
        },
        //Function to run when audio is paused by user
        pauseListener() {
            this.player.isPlaying = false;
            this.player.listenerActive = false;
            this.cleanupListeners();
        },
        //Function to run when audio play reaches the end of file
        endListener() {
            this.player.isPlaying = false;
            this.player.listenerActive = false;
            this.cleanupListeners();
        },
        //Remove listeners after audio play stops
        cleanupListeners() {
            let audio = this.$refs.player;
            audio.removeEventListener("timeupdate", this.playbackListener);
            audio.removeEventListener("ended", this.endListener);
            audio.removeEventListener("pause", this.pauseListener);
        },
        setCurrentTime(percentage) {
            let audio = this.$refs.player;
            let totalTime = this.player.audioDuration;
            this.player.playbackTime = percentage * totalTime / 100;

            audio.currentTime = this.player.playbackTime;
        },
        uploadSound() {
            this.uploading = true;
            this.errorMessage = null;
            const self = this;
            let formData = new FormData();
            for (let i = 0; i < this.$refs.userSound.files.length; i++) {
                let file = this.$refs.userSound.files[i];
                formData.append('songs[' + i + ']', file);
            }

            axios.post('/api/user-music/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            }).then(response => {
                self.uploading = false;

                if(!response.data || !response.data.length) {
                    return;
                }
                    this.mySongs.list = [].concat(this.mySongs.list, response.data);
                    this.$emit('updateActiveSong', response.data);
                    if(this.$refs.player){
                        this.$refs.player.load();
                    }
            }).catch((error) => {
                if (error.response) {
                    self.uploading = false;
                    self.errorMessage = error.response.data.message;
                }

            });
        },
        loadUserSongs() {
            axios.get('/api/user-music/?page=' + this.mySongs.page).then(response => {
                if (response.status == 200) {
                    let newSongs = response.data.data;
                    this.mySongs.list = [].concat(this.mySongs.list, newSongs);
                    if (response.data.last_page == this.mySongs.page) {
                        this.mySongs.finished = true;
                    }
                }
            }).catch(err => {
                console.log(err)
            });
        },
        deleteMySong(position, id) {
            axios.delete('/api/user-music/' + id).then((response) => {
                this.mySongs.list.splice(position, 1);
                this.setNoSound();
            }).catch(err => {
                console.log(err)
            });
        },
        onDivScroll() {
            const listElm = this.$refs.listEl;
            const distanceFromBottom =
                listElm.scrollHeight - listElm.scrollTop - listElm.clientHeight;

            if (distanceFromBottom < 1 && !this.finished && !this.isLoading) {
                this.songGallery.loading = true;
                this.songGallery.page++;
                this.loadSongsFromGallery();
            }

        },
        setNoSound() {
            this.$emit('updateActiveSong', {});
            if (this.player.isPlaying) {
                this.toggleAudio();
            }
        },
        playMySong(playSong, index) {
            this.playCategory.mySongs = true;
            this.playCategory.index = index;
            this.$emit('updateActiveSong', playSong);
            this.$refs.player.load();
            this.$refs.player.play();
            this.player.isPlaying = true;
        },
        loadSongCategories() {
            axios.get('/api/songs-gallery/categories').then((response) => {
                this.songGallery.categories = response.data;
            }).catch(err => {
                console.log(err)
            });
        },
        setActiveCategory(category) {
            this.songGallery.activeCategory = category;
            this.songGallery.list = [];
            this.songGallery.page = 1;
            this.songGallery.finished = false;
            this.setNoSound();
            this.loadSongsFromGallery();
        },
        loadSongsFromGallery() {
            axios.get('/api/songs-gallery/' + this.songGallery.activeCategory + '?page=' + this.songGallery.page).then(response => {
                if (response.status == 200) {
                    let newSongs = response.data.data;
                    this.songGallery.list = [].concat(this.songGallery.list, newSongs);
                    if (response.data.last_page == this.songGallery.page) {
                        this.songGallery.finished = true;
                    }
                }
            }).catch(err => {
                console.log(err)
            });
        },
        onScrollSongsGallery() {
            const listElm = this.$refs.listElSoundGallery;
            const distanceFromBottom =
                listElm.scrollHeight - listElm.scrollTop - listElm.clientHeight;

            if (distanceFromBottom < 1 && !this.finished && !this.isLoading) {
                this.mySongs.loading = true;
                this.mySongs.page++;
                this.loadUserSongs();
            }
        },
        playSongFromGallery(playSong, index) {
            this.playCategory.mySongs = false;
            this.playCategory.index = index;
            this.$emit('updateActiveSong', playSong);
            this.$refs.player.load();
            this.$refs.player.play();
            this.player.isPlaying = true;
            // this.toggleAudio();
        },

        playPreviousSong() {
            if (!this.canPlayPrevious) {
                return;
            }
            let newIndex = this.playCategory.index - 1;

            if (this.playCategory.mySongs) {
                this.playMySong(this.mySongs.list[newIndex], newIndex)
            } else {
                this.playSongFromGallery(this.songGallery.list[newIndex], newIndex)
            }
        },
        playNextSong() {
            if (!this.canPlayNext) {
                return;
            }
            let newIndex = this.playCategory.index + 1;

            if (this.playCategory.mySongs) {
                this.playMySong(this.mySongs.list[newIndex], newIndex)
            } else {
                this.playSongFromGallery(this.songGallery.list[newIndex], newIndex)
            }
        }
    }
}
</script>

<style scoped>

</style>
